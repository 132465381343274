
import React, { useContext, useState } from 'react';

import EventsOverview from '../../pages/events'
import {  graphql } from "gatsby"

import * as style from '../../styles/allEventPage.module.css'

export default function EventSub({data}){
 let selection = data.allCmbEventsJson.nodes[0].jsonId;
console.log(selection)
  return <EventsOverview selectedEvent={selection}/>
}


 export const query = graphql`
 query($id:String) {
   
  
  
  allCmbEventsJson(filter:{id:{eq:$id}}){
      nodes {
        date
        en {
          eventMain
          eventSide
        }
        hr {
          eventMain
          eventSide
        }
        jsonId
        language
        location
        mainImage
        presenter
        time
        sideImage
      }
    }
  }`



